<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 473.2 450.02"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M99.59,249.64C44.75,249.62.24,294.39.11,349.72a100.2,100.2,0,0,0,200.4.3C200.86,294.73,155.56,249.67,99.59,249.64Z"/><path d="M375,1.27,276,98.08l1,1.39h73.69V449.63h49.07V99.45H473.2Z"/><path d="M100.29,200.44A100.22,100.22,0,1,0,99.63,0C44.52.22.06,45.29,0,100.53-.06,156.29,46.54,200.86,100.29,200.44Zm.07-149.8a49.74,49.74,0,0,1,49.55,49.6c.07,27-22.52,49.68-49.59,49.77-27.49.08-50-22.84-49.76-50.59C50.83,72.5,73.25,50.53,100.36,50.64Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconBIC',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
